:root {
    --zoom: 1.0
}

// @media screen and (min-width: 960px) and (max-width: 1919px) {
//     $zoom: 1;
// }

@media screen and (min-width: 1920px) {
    :root {
        --zoom: 1.2;

        .no-zoom {
            --zoom: 1
        }
    }
}