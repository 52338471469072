@use './colors'as color;

// mat-form-field {
//     zoom: var(--zoom);
// }

.mat-form-field-appearance-legacy .mat-form-field-label {
    font-weight: 500;
    color      : color.$black;
}

.mat-chip-list-wrapper .mat-chip.mat-standard-chip {
    background-color: color.$greyish;
}