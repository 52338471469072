$blue     :#007AC2;
$lightblue: #0A8DE0;
$white    : #ffffff;
$greyish  : #FAFAFA;
$greyish2 : #F0F0F0;
$red      : #C21E00;
$black    : #2E3241;
$lightgrey: #D4D5D7;
$green    : #15BEA8;
$yellow   : #E6D026;
$orange   : #D08001;
$purple   :#013D83;
$lime     : #D6C600;