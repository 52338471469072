@use '@angular/material' as mat;
@import './assets/_custom.scss';
@import './assets/_input.scss';

// Projeto Ecossistema UC021
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

//@import '~saturn-datepicker/bundle.css';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.
@import '~ngx-toastr/toastr';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$front-end-primary: mat.define-palette(mat.$indigo-palette);
$front-end-accent : mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$front-end-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$front-end-theme: mat.define-light-theme($front-end-primary, $front-end-accent, $front-end-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($front-end-theme);

// Fonts
// 'Montserrat', sans-serif;
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

/* You can add global styles to this file, and also import other style files */
html,
body {
    height: 100%;

    // zoom: var(--zoom);

    // -moz-transform: scale(var(--zoom));
}

body {
    margin          : 0;
    background-color: white;
}

* {
    font-family: 'Montserrat', sans-serif;
}

*:focus {
    outline: none;
}

/* scroll */
/* width */
::-webkit-scrollbar {
    width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
    background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #F2F2F2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
}

/* scroll - end*/

a,
.pointer {
    cursor: pointer;
}

.underline {
    text-decoration: underline;
}

.project-detail-content {
    .mat-drawer {
        position: fixed;
    }

    .mat-drawer-content {
        display: unset;
    }

    .mat-drawer-backdrop {
        position        : fixed;
        background-color: unset;
    }
}

.cdk-overlay-pane.scrollable {
    overflow-y: auto;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #15BEA8;
}

.vocacao-list {
    .mat-expansion-panel-content {
        background-color: #FAFAFA;
    }
}

.potencial-list {
    .mat-expansion-panel-content {
        background-color: #FAFAFA;
    }
}

.mat-card-image {
    margin        : 0 -16px 0 -16px !important;
    padding-bottom: 16px;
}

.ql-container.ql-snow{
    border: none !important;
    padding: 0px;
}
.ql-toolbar.ql-snow{
    border: none !important;
    padding-top: 0px;
}

// .ql-container {
//     height: auto;
//    // flex: 1;
//     //display: flex;
//     //flex-direction: column;
//    //
// }

.ql-container {
    height: calc(100% - 42px);
  }

.ql-editor {
    flex: 1;
    width: 100%;
    height: auto;
    overflow-y: scroll;
    background: var(--unnamed-color-fbfbfb) 0% 0% no-repeat padding-box;
}




.mat-tooltip.tooltip-font
{
  font-size: 12px;
}

@font-face {
  font-family: "Alegreya Sans ExtraBold";
  src: url('./assets/homePage/fonts/AlegreyaSans-ExtraBold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya Sans Regular";
  src: url('./assets/homePage/fonts/AlegreyaSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya Sans Light";
  src: url('./assets/homePage/fonts/AlegreyaSans-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya Sans Bold";
  src: url('./assets/homePage/fonts/AlegreyaSans-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya Sans Medium";
  src: url('./assets/homePage/fonts/AlegreyaSans-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}
