@use '../assets/colors'as color;

.pointer {
    cursor: pointer;
}

.txt-center {
    text-align: center;
}

.project-list {
    .mat-card {
        padding: 0 16px 16px;
    }
}

.no-padding-modal .mat-dialog-container {
    padding: 0px !important;
}

.mat-chip-list-stacked .mat-chip-list-wrapper .mat-standard-chip {
    width: unset !important;
}

.mat-tab-list {
    .mat-tab-label-active {
        color           : color.$blue;
        background-color: rgb(0, 122, 194, 0.2);

        font-size  : 16px;
        font-weight: 700;
    }

    .mat-tab-label {
        font-size  : 16px;
        font-weight: 700;
    }


    .mat-tab-labels div {
        height: 33px;
    }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: color.$blue !important;
}

.input-grey-background {
    .mat-form-field-outline {
        // border       : 1px solid #D4D5D7;
        border-radius: 5px;

        background-color: color.$greyish2;
    }

    .blue-mat-label {
        mat-label {
            font-size   : 18px;
            // color    : color.$blue;
        }
    }

    // mat-label{
    //     color: #f44336;
    // }
}

.small-form-field {
    mat-form-field {
        font-size: 12px
    }
}

.grey-text-area {
    .mat-form-field-flex {
        background-color: color.$greyish;
        border          : 1px solid color.$lightgrey;
        padding         : 0 16px;
    }
}

.mat-calendar-body-cell {
    .mat-calendar-body-selected {
        background-color: color.$blue;
    }
}

.mat-calendar-body-cell.mat-calendar-cell-semi-selected {
    background-color: rgb(0, 122, 194, 0.2);
}

.mat-calendar-body-cell.mat-calendar-body-begin-range {
    background-color: rgb(0, 122, 194, 0.2);
}

.mat-calendar-body-cell.mat-calendar-body-end-range {
    background-color: rgb(0, 122, 194, 0.2);
}

.plan {
    mat-checkbox {
        .mat-checkbox-label {
            white-space: normal;
        }
    }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: color.$green !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgb(21, 190, 168, 0.3) !important;
}